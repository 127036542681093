<template>
  <div class="wrapper">
    <div v-if="loading" class="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="card">
        <div class="title">
          <div class="caption">{{ $t('reset_two_factor_auth') }}</div>
        </div>
        <div class="content">
          <div class="content-box mt-2">
            <div v-if="type === 'qrcode'">
              <div v-html="$t('google.step2.content.qrcode')"></div>
              <div class="mt-3 text-center">
                <div v-html="google.qrcode" style="display: inline-block; border: 1px solid #E5E7EB"></div>
              </div>
              <div class="mt-3 text-center" style="color: #078ABC; cursor: pointer" @click="change('text')">{{ $t('google.step2.alter.text') }}</div>
            </div>
            <div v-if="type === 'text'">
              <div v-html="$t('google.step2.content.text')"></div>
              <div class="key-box mt-3">
                <div class="text-center" style="font-weight: 500" id="textArea">{{ google.secret }}</div>
                <div class="mt-2">
                  <button class="key-button" @click="duplicate">{{ $t('google.step2.copy_secret') }}</button>
                </div>
              </div>
              <div class="mt-3 text-center" style="color: #078ABC; cursor: pointer" @click="change('qrcode')">{{ $t('google.step2.alter.qrcode') }}</div>
            </div>
          </div>
          <div class="mt-5">
            <button class="btn btn-solid btn-blue" @click="next">{{ $t('next') }}</button>
          </div>
          <div class="mt-4">
            <div class="link link-info">
              <div style="font-size: 14px" @click="cancelled">{{ $t('cancelled') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateKey, info } from '../api/2fa'

export default {
  name: 'verify.google.reset.scan',
  data () {
    return {
      loading: false,
      type: 'qrcode',
      google: {
        secret: '',
        qrcode: ''
      }
    }
  },
  created () {
    if (this.$route.params.mode === undefined) {
      this.$router.push({
        name: 'verify.index'
      })
    }
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      const retInfo = await info()
      const ret = await generateKey('google', { email: retInfo.data.customer.email })
      this.google.secret = ret.data.secret_key
      this.google.qrcode = ret.data.qr_image
      this.loading = false
    },
    change (type) {
      this.type = type
    },
    next () {
      this.$router.push({
        name: 'verify.google.reset.bind',
        params: {
          mode: true,
          secret: this.google.secret
        }
      })
    },
    duplicate () {
      const content = document.getElementById('textArea').innerHTML
      navigator.clipboard.writeText(content).then(() => {
        alert('copied')
        return true
      }).catch(err => {
        console.log('wrong', err)
      })
    },
    cancelled () {
      this.$router.push({
        name: 'verify.index'
      })
    }
  }
}
</script>
